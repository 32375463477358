.rupeshseva_hero_cursole_divss5{
    border: 2px solid black;
    width: 100%;
    display: flex;
}
.rupeshseva_hero_cursole_image{
    border:2px solid red;
    width: 100%;
    
}
.rupeshseva_hero_cursole_paragraph{
    border: 2px solid black;
    width: 80%;
   
}
.rameshseva_button_cursole{
    border: 2px solid black;
}
.div1_rupeshhero_image{
    border: 2px solid black;
    width: 70%;
}
.paragrapgh_rupesh{
    font-size: 50px;
}
.video_herorupesh{
    width: 100%;
    height:  auto;
    /* border: 3px solid; */
}