.rupeshseva_about_main_about {
    text-align: center;
    background-color: #1B4F72;
    color: black;
    height: auto;
    width: 94%;
    margin-left: 3%;
    padding-top: 3%;
    margin-top: 3%;
    border-radius: 20px;
}

.rupeshseva_about_description {
    padding: 35px;
    color: white;
    font-family: sans-serif;
}

.rupeshseva_about_description_div {
    width: 95%;
    display: flex;
    column-gap: 70px;
    padding: 30px;
    margin-top: -3%;
}
* {
    margin: 0;
    padding: 0;
}

.rupeshseva_about_description1 {
    width: 50%;
    padding: 20px;
    margin-left: 5%;
    background-color: white;
    border: 2px solid gold;
    color: black;
    border-radius: 20px;
}

.rupeshseva_about_description2 {
    width: 50%;
    padding: 20px;
    border-radius: 20px;
    border: 2px solid gold;
    color: black;
    background-color: white;
}

.rupeshseva_about_heading {
    color: white;
    text-align: center;
    padding: 2%;
    font-weight: 60px;
    margin-top: -3%;
}

@media screen and (max-width:700px) {

    .rupeshseva_about_description1 {
        width: 90%;
        height: auto;
        padding: 20px;
        margin-left: 5%;
        background-color: white;
        border: 2px solid gold;
        color: black;
        border-radius: 20px;
    }

    .rupeshseva_about_description2 {
        width: 90%;
        height: auto;
        margin-left: 5%;
        margin-top: 15px;
        padding: 20px;
        border-radius: 20px;
        border: 2px solid gold;
        color: black;
        background-color: white;
    }

    .rupeshseva_about_description_div {
        width: 100%;
        display: block;
        column-gap: 0px;
        padding: 10px;
        margin-top: 3%;
    }

    .rupeshseva_about_main_about {
        text-align: center;
        background-color: #1B4F72;
        color: white;
        height: auto;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        padding-top: 3%;
        margin-top: 20%;
        border-radius: 20px;
    }

    .rupeshseva_about_heading {
        color: white;
        text-align: center;
        padding: 2%;
        font-size: 20px;
        margin-top: -3%;
    }

}