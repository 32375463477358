.rupeshseva_hero_div1{
    background-color: rgb(51, 50, 53);
    height:auto;
    width: 100%;
    display: flex;
    border: 2px solid ;
}

.rupeshseva_hero_div2{
    border: 2px solid green;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.rupeshseva_hero_span1{
    margin-left: 1%;
  /* margin-bottom: 0.5%; */
  /* text-align: center; */
  color: white;
  border: 2px solid greenyellow;
  display: flex;
  width: 30%;
  padding: 1%;
  justify-content: space-evenly;
  
}
.rupeshseva_hero_content{
    /* margin-left: 5%; */
    font-weight: 500;
    font-size: larger;
    color: black;
    /* height: 5vh; */
    width: 100%;
    cursor: pointer;
    /* border: 2px solid ; */
  }
.rupeshseva_hero_content:hover{
    border-bottom: 2px solid rgb(51, 50, 53) ;
    color: rgb(51, 50, 53);

   
  }
  .logoof_header{
    width: 100%;
    /* border: 2px solid black; */
    height: auto;
    /* width: 29vh; */
  }
  .contentitems_col{
    /* border: 2px solid black; */
   padding-top: 7%;
   width: 12%;
   
    
  }
  .all_content_row{
    /* border: 2px solid black; */
    
  }
  .Home_content{
    float: right;
    /* border: 2px solid red; */
  }


 .rupesh_hero_contener_div4{
      padding-top: 4%;
      width: 60%;
      /* border:  2px solid black; */
 }
 .rupesh_hero_heading_div3_main{
 
  height: auto;
  background-color: white;
  width: 100%;
  border: 2px solid ;
 }
 .rupesh_hero_heading_div3 {
  
    width: 100%;
    display: flex;
    column-gap: 1%;
    color: rgb(184, 128, 237);
    
}
.rupeshseva_button_enquiry{
    background-color: rgb(51, 50, 53);
    width: 80px;
    border-radius: 20px;
    color: white;
    margin-left: 5%;
    text-align: center;
    padding: 1%;
    padding-right: 2.5%;
  }
.rupeshseva_hero_cursole_div5{
  width: 100%;
  height:80vh;
  column-gap: 2%;
 /* background-image: url('D:\react Project2\ems\src\photos\background2.avif'); */
 background-image: url('/background2.avif');
 background-repeat: no-repeat;
 background-size: cover;
  display: flex;

}
.rupeshseva_hero_cursole_paragraph{
  width: 50%;
  font-weight: 70px;
  font-size: 100px;
  text-align: center;
  font-family:"Kruti Dev"  ;
  color: rgb(242, 245, 223);
  margin-top: 3%;

}
.rupeshseva_hero_cursole_image{
  width: 50%;
  height: 75vh;
  padding: 1%;
  margin-top: 2%;

}
.rameshseva_button_cursole {
  position: relative;
  overflow: hidden;
  height: 3rem;
  padding-right: 4%;
  border-radius: 1.5rem;
  background: white;
  background-size: 400%;
  color: rgb(51, 50, 53);
  border: 1px solid rgb(51, 50, 53);
  cursor: pointer;
  position: absolute;
  margin-top: 8%;
 margin-left: -8%;
 height: 6vh;
  width: 250px;
  font-family:'Times New Roman', Times, serif;
  font-size: 18px;
}

.rameshseva_button_cursole:hover::before {
  transform: scaleX(1);
}
.rameshseva_button_cursole::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  color: white;

  border-radius: inherit;
  background: linear-gradient(
    82.3deg,
    rgb(51, 50, 53) 10.8%,
    rgb(51, 50, 53) 94.3%
  );
  transition: all 0.475s;
}
.rameshseva_button_cursole:hover{
  color: white;
  
}
.rameshseva_button_content_cursole {
  position: relative;
  z-index: 1;
  
}
.shivhero_logo_img{
  height: 200px;
  width: 200px;
margin-bottom: 18%;
margin-top: -4%;
}
.main_row{
  background-color: #1B4F72;
  color: white;
  padding: 10px;
}
.main_head_col{
  width: 50%;
 
}
#contact_no_id{
  float: right;
  padding-right: 10px;
  font-size: 20px;
}
/* @media screen and (max-width:700px){ */


@media screen and (max-width:700px){
.main_head_col{
  width: 100%;
  padding-top: 15px;
  text-align: center;
}
#contact_no_id{
  float: none;
}
.rupesh_hero_contener_div4{
  /* padding-top: 4%; */
  width: 100%;
  border:  0px solid black;
}
.rupesh_hero_heading_div3 {
  
  width: 100%;
  display: block;
  column-gap: 1%;
  color: rgb(184, 128, 237);
  
}
.logoof_header{
  width: 100%;
  /* border: 2px solid black; */
  /* height: 27vh; */
  padding-left: 22%;
  /* float: right; */
  /* width: 29vh; */
  /* float: none; */
}
.main_row{
  display: none;
}
}

