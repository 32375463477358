.heading{
    text-align: center;
    width: 100%;
    padding: 10px;
    background-color: #1B4F72;
    color: gold;
    
  }
  .DIV1{
    display: flex;
  }
  .CARD1,.CARD2{
    width: 48%;
    border:3px solid gold;
    margin-top: 2%;
    margin-left: 1%;
  }
  
  @media screen and (max-width: 700px) {
    .DIV1{
        display: block;
    }
    .CARD1,.CARD2{
        width: 100%;
    }
    .heading{
      text-align: center;
      width: 100%;
      padding: 10px;
      
    }
  }
  
  