.recharge_services_maindiv {
    width: 100%;
    flex-wrap: wrap;
    text-align: center;
    margin: auto;
    display: grid;
    place-items: center;
    background-color: #ffffff;
  }
  .recharge_services_heading {
    color: gold;
    background-color: #1B4F72;
    padding: 5px 70px 5px 70px;
    font-size: 20px;
    border-radius: 20px;
    border: solid 2px gold;
    margin: 15px 0px 15px 0px;
  }
  
  .recharge_block {
    min-height: 60vh;
    display: flex;
    justify-content: space-evenly;
    column-gap: 2%;
    width: 100%;
    padding-top: 2%;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  

  .recharge_name_div {
    height: auto;
    width: 13%;
    padding: 10px;
    background-color: orange;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  
  .Img4-type_Img {
    height: 10vh;
    width: 100%;
    border-radius: 50%;
    color: white;
    background-color: white;
  }
  
  .Img4-name_h4 {
    color: white;
    font-size: 17px;
    text-align: center;
  }
  
  @media screen and (max-width:800px) {
  
    .recharge_services_maindiv {
      width: 99%;
      text-align: center;
  
    }
    .recharge_block {
      height: auto;
      width: 100%;
      flex-wrap: wrap;
      background-color: rgb(250, 244, 244);
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }
    .recharge_name_div{
      width: 40%;
      padding-top: 1%;
      margin-top: 9%;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }
  
  
  }