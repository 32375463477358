.RupeshGrahk_contactdiv {
    height: auto;
    display: flex;
    width: 100%;
    background-color: rgba(223, 233, 195, 0.5);
    border-radius: 20px;


}

.firstdivof_Contact {
    padding-top: 3.5%;
    padding-bottom: 3%;
    width: 50%;
}

.seconddivof_Contact {
    height: auto;
    width: 50%;
    padding-top: 2%;
}

.header_contact {
    text-align: center;
    padding: 4%;
    color: black;
    font-family: sans-serif;
    font-size: 40px;

}

.subpara_contact {
    /* border: 2px solid black; */
    padding: 30px;
    margin-left: 17px;
}

.div_contact_map {
    margin-left: 45px;
}

.seconddiv_heading {
    padding: 30px;
    /* border: 2px solid brown; */
    text-align: center;
    color: gold;
}

.button {
    width: 100%;
    background-color: #1B4F72;
    color: gold;
    padding: 5px;
    border-radius: 20px;
    margin-top: 2%;
}

.button:hover {
    color: #1B4F72;
    background-color: gold;
}

.googlemap_rupesh {
    padding-left: 0%;
}

@media screen and (max-width:1000px) {
    .googlemap_rupesh {
        width: 90%;
        
    } 
}



@media screen and (max-width:700px) {
    .RupeshGrahk_contactdiv {
        /* border: 2px solid black; */
        height: auto;
        display: block;
        width: 100%;



    }

    .seconddivof_Contact {
        margin-left: 5%;
        height: auto;
        width: 90%;
        margin-right: 5%;
    }

    .firstdivof_Contact {
        /* border: 2px solid brown; */
        height: auto;
        width: 100%;
        text-align: center;




    }

    .button {
        width: 100%;
        margin-left: 0px;
        background-color: #1B4F72;



    }

    .googlemap_rupesh {
        width: 100%;
        padding-right: 10%;
    }

}