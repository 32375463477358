.rupeshseva_why_heading{
    text-align: center;
    font-size: 20px;
    margin-top: -1%;
    margin-bottom: 2%;
  background: linear-gradient(to right, rgb(51, 50, 53), green);
    -webkit-background-clip: text ;
    color: white;
  
  }
  .rupeshseva_whytouse_main_divwhy{
    padding: 50px;
    height: auto;
    width: 92%;
    margin-left: 4%;
    margin-top: 5%;
    background: #1B4F72;
    border-radius: 0px 30px 0px 30px;
    
  }
  .rupeshseva_whytouse_para{
    padding-top: 1%;
    font-size: 18px;
    color: white;
  
  }
  @media screen and (max-width:700px){
    .rupeshseva_whytouse_main_divwhy{
      padding: 20px;
      height: auto;
      width: 92%;
      margin-left: 4%;
      margin-top: 5%;
      background: #1B4F72;
      border-radius: 0px 30px 0px 30px;
      
    }
  }
  