.rupeshseva_eightdiv_first_div{
    padding: 0px;
    text-align: center;
    margin-top: 5%;
  }
  .rupeshseva_eightdiv_Eight_div{
    /* border: 2px solid ; */
    padding: 20px;
  
  }
  .rupeshseva_eightdiv_first_row{
    display: flex;
    /* border: 2px solid ; */
    column-gap: 50px;
    margin: 50px auto;
  
  }
  .rupeshseva_eightdiv_div1{
    width: 60%;
    border: 2px solid ;
    padding: 30px;
    margin-left: 10%;
    height: auto;
    background-color: #1B4F72;
    color:#fff;
    border: 2px solid gold;
    border-radius: 1px 80px 1px 80px;
    font-family: sans-serif;
    font-weight: 100;
  }
  .rupeshseva_eightdiv_div2{
    width: 60%;
    border: 2px solid ;
    padding: 30px;
    height: auto;
    margin-right: 10%;
    background-color: #1B4F72;
    color:#fff;
    border: 2px solid gold;
    border-radius: 1px 80px 1px 80px;
    font-family: sans-serif;
    font-weight: 100;
  
   
  }
  .rupeshseva_eightdiv_lastthird_div{
    padding: 5px;
    /* border: 2px solid ; */

    text-align: center;
  }

  


















  @media screen and (max-width: 700px) {
    
  
      .rupeshseva_eightdiv_first_div{
        padding: 10px;
        text-align: none;
        margin-top: 5%;

      }
      .rupeshseva_eightdiv_div1{
        width: 95%;
        border: 2px solid ;
        padding: 15px;
        margin-left: 1%;
        height: auto;
        background-color: #1B4F72;
        color:#fff;
        border: 2px solid gold;
        border-radius: 1px 80px 1px 80px;
      }
      .rupeshseva_eightdiv_first_row{
        display: block;
        margin: 10px auto;
      }
      .rupeshseva_eightdiv_div2{
        width: 95%;
        border: 2px solid ;
        height: auto;
        padding: 15px;
        margin-left: 1%;
        margin-top: 3vh;
        margin-right: 0%;
        background-color: #1B4F72;
        color:#fff;
        border: 2px solid gold;
        border-radius: 1px 80px 1px 80px;
      
       
      }

      .rupeshseva_eightdiv_lastthird_div{
        padding: 10px;
        text-align: none;
        margin-top: 5%;
      }
    
  }