
.footer_rupesh{
    display: flex;
    height: auto;
    background-color: #1B4F72;
    padding-bottom: 10px;
  }
  .one_div{
    height: auto;
    width: 35%;
  }
  .other_div{
    height: auto;
    width: 20%;
  }
  .name{
    font-size:20px ;
    padding: 20px;
    border-radius: 40px;
    margin-left: 80px;
    margin-top: 8%;
    height: auto;
    width: 55%;
    background-color: gold;
    color: black;
  
  }
  .describe{
    height: auto;
    margin-right: 20px;
    margin-left: 10%;
    color: white;
  }
  .header_links{
    margin-top: 5vh;
    color:gold;
    font-weight: 500;
    font-family: sans-serif;
    
  }
  .header_linksdiv{
    margin-left: 1%;
    color: white;
  }

  ul li{
    list-style: none;
    margin-bottom: 5px;
  }
  .copyright{
    background-color: gold;
    text-align: center;
  }
  .copyright a h5{
    font-family: sans-serif;
    font-weight: 200
  }

  @media (max-width:700px){
    .footer_rupesh{
      display: block;
    }
    .one_div{
      width: 100%;
      text-align: center;
      padding-top: 3px;
    }
    .other_div{
      width: 100%;
      text-align: center;
    }
  }
  