.Main_services_block{
    /* height: 60vh; */
    width: 100%;
    /* height: 2px solid; */
    text-align: center;
    /* border: 2px solid; */
    background-color:gold;
}
.Services_Main_page{
    height: 10vh;
    font-family: amino pro;
    padding: 20px;
}
.h1s2{
    color: white;
    }
.divs_block{
height: 60vh;
display: flex;
justify-content: space-evenly;
column-gap: 2%;
width: 100%;
/* border: 2px solid; */
padding-top: 3%;
flex-wrap: wrap;
background-color: rgb(250, 244, 244);
box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.Img--name_div{
    height: 22vh;
    width: 12%;
    border: 2px solid rgb(112, 185, 112);
    background-color: gold;
    padding-top: 1%;
    border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.Img-type_Img{
    height: 8vh;
    width: 50%;
    border-radius: 50%;
    color: white;
    background-color: white;
    margin-left: 25%;
}
.Img-name_h4{
    color: white;
    font-size: 17px;
}




@media screen and (max-width:800px){

    .Main_services_block{
        /* height: 60vh; */
        width: 99%;
        /* height: 2px solid; */
        text-align: center;
        /* border: 2px solid; */
        background-color: rgb(34, 136, 98);
    }
    .Services_Main_page{
        height: 15vh;
        font-family: amino pro;
    }
    .divs_block{
        height: auto;
        display: flex;
        justify-content: space-evenly;
        column-gap: 2%;
        width: 100%;
        /* border: 2px solid; */
        padding-top: 5%;
        flex-wrap: wrap;
        background-color: rgb(250, 244, 244);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        }
        .Img--name_div{
            height: 17vh;
            width: 40%;
            /* border: 2px solid; */
            /* display: grid; */
            margin-top: 9%;
            border-bottom-left-radius: 10%;
            border-top-right-radius: 20%;
            background-color: hsl(32, 81%, 59%);
            padding-top: 1%;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        }
}
