.Services_CSC_Main_divs{
  height: 100%;
  width: 100%;
  

}
.rupeshseva_service_div1{
    height: auto;
    width: 100%;
    background-color: rgba(223, 233, 195, 0.5);
    display: flex;
    justify-content: space-evenly;
    border-radius: 20px;
    flex-wrap: wrap;
    
  }
  .rupeshseva_service_heading{
    font-family: sans-serif;
    font-size: 40px;
    font-weight: 600;
    background: black;-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    padding: 2%;
  }
  .rupeshseva_service_img{
   /* margin-left: 1%; */
   text-align: center;
    
  }
  .rupeshseva_service_description{
    font-family: 'Kurti dev';
    margin-top: 6%;
  }
  .rupeshseva_service_card1{
    background: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); 
    height: auto;
    width: 20%;
    text-align: center;
    margin-top: 3%;
    margin-bottom: 1%;
    border-radius: 5px 30px 5px 30px;
  }
  .rupeshseva_service_button {
    background: linear-gradient(150deg,gold,#1B4F72);
    border: none;
    color: white;
    text-align: center;
    font-size: 16px;
    /* padding-bottom: 1% !important; */
    border-radius: 25px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    /* transition: all 0.3s ease; */
    padding-right: 15%;
    /* text-align: center; */
  }
  
  .rupeshseva_service_button:hover {
    background: linear-gradient(135deg, #a777e3, #6e8efb);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
    color: black !important;
  }
  
  .rupeshseva_service_button:active {
    transform: translateY(2px);
  } 
  @media screen and (max-width:800px){
    
    .Services_CSC_Main_divs{
      height: 100%;
      width: 100%;
      /* border: 2px solid; */
     
    
    }
    .rupeshseva_service_div1{
      
      width: 100%;
      background-color: rgba(223, 233, 195, 0.5);
      border-radius: 20px;
      flex-wrap: wrap;
      
      /* text-align: center; */
    }
    .rupeshseva_service_card1{
      background: #fff;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); 
      height: auto;
      width: 80%;
      text-align: center;
      /* margin-top: 2%; */
      border-radius: 5px 30px 5px 30px;
    }
  }